export class SongList {
  id: number;
  name: string;
  description: string;
  ordered: boolean;
  imgUrl: string;
  dateStr: string;
  date: number;
  lastUsedDate: number;
  songIds: number[];
}

export class Song {
  id: number;
  name: string;
  midifile: string;
  duration: number;
  pdfs: Pdf[];
  mp3: {[voice: string]: string} = {};
  links: string[];
  lyrics: {[voice: string]: string} = {};
  lastUpdated: number;
  lastViewed: number;
}

export class Pdf {
  link: string;
  name: string;
}

export class Archive {
  songs: Song[];
  lists: SongList[];
  urlPrefix: string;
}

export class UserProfile {
  name: string;
  voice: Voice;
}

export interface Voice {
  value: string;
  viewValue: string;
  prio: string[];
}

export const favouriteSongListId = -1;

export const voices: Voice[] = [
  {value: 'S1', viewValue: 'Sopran 1', prio: ['S1', 'S', 'Dam']},
  {value: 'S2', viewValue: 'Sopran 2', prio: ['S2', 'S', 'Dam']},
  {value: 'A1', viewValue: 'Alt 1', prio: ['A1', 'S', 'Dam']},
  {value: 'A2', viewValue: 'Alt 2', prio: ['A2', 'A', 'Dam']},
  {value: 'T1', viewValue: 'Tenor 1', prio: ['T1', 'T', 'Herr']},
  {value: 'T2', viewValue: 'Tenor 2', prio: ['T2', 'T', 'Herr']},
  {value: 'B1', viewValue: 'Bas 1', prio: ['B1', 'B', 'Herr']},
  {value: 'B2', viewValue: 'Bas 2', prio: ['B2', 'B', 'Herr']},
];

import { Injectable } from '@angular/core';
import * as midiplayer from 'midi-player-ts';
import { HttpClient } from '@angular/common/http';
import { Song, SongList, UserProfile } from './models/models';
import { BehaviorSubject } from 'rxjs';
import { IAppState, updateSongs, setCurrentSong } from './store';
import { Store, select } from '@ngrx/store';
import { selectCurrentSong, selectCurrentSongList, selectUrlPrefix, selectUserProfile } from './store/selectors';
import { skipWhile } from 'rxjs/operators';
import { matchSecurity } from './utils';

// midi parser implementation and documentation: https://www.npmjs.com/package/midi-player-ts

declare var WebAudioFontPlayer: any;

// dont forget to add new instrument files to index.html as well
// more instruments can be found here: https://github.com/surikov/webaudiofont#catalog-of-instruments

// declare var _tone_0000_JCLive_sf2_file: any; // piano
// declare var _tone_0690_SBLive_sf2: any; // oboe

declare var _tone_0000_Aspirin_sf2_file: any; // piano
declare var _tone_0680_Aspirin_sf2_file: any; // oboe

const instrumentsToLoad: string[] = ['_tone_0680_Aspirin_sf2_file', '_tone_0000_Aspirin_sf2_file'];

const commonNames: {[name: string]: string} = {};

export class LyricsSyllable {
  time: number;
  syllable: string;
  index: number;
}

enum PlayerMode {
  mp3,
  midi
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private piano = _tone_0000_Aspirin_sf2_file;
  private oboe = _tone_0680_Aspirin_sf2_file;
  private midiParser: midiplayer.Player = new midiplayer.Player();
  private audioContext: AudioContext = new AudioContext();
  private midiPlayer = new WebAudioFontPlayer();

  private mp3Player = new Audio();
  private trackNamesByNum: {[trackNumber: number]: string} = {};
  private trackNumByName: {[trackName: string]: number} = {};
  private lyrics: {[tracknumber: number]: LyricsSyllable[][]} = {};

  public isPlaying$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public position$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public duration$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentLyrics$: BehaviorSubject<LyricsSyllable[][]> = new BehaviorSubject<LyricsSyllable[][]>([]);
  public tempo$: BehaviorSubject<number> = new BehaviorSubject<number>(100);

  public tracknames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private mode: PlayerMode = PlayerMode.mp3;

  public currentSong: Song;
  private tickRatio = -1;
  private notes: {[track: number]: any} = {};
  private instruments: {[track: number]: any} = {};
  private volume: {[track: number]: number} = {};
  private trackNames: string[];
  private playOnNextLoad: boolean;
  currentSongList: SongList;
  urlPrefix: string;
  userProfile: Partial<UserProfile>;
  private originalMidiTempo: number;

  private getTickRatio() {
    if (this.tickRatio <= 0) {
      this.tickRatio =  10320 / 26.875;
    }
    return this.tickRatio;
  }

  constructor(private http: HttpClient, private store: Store<IAppState>) {
    // init midiparser events
    this.midiParser.on('midiEvent', (ev: midiplayer.Event) => { this.playMidiEvent(ev); });

    this.midiParser.on('playing', (currentTick) => {
      const next = Math.round( currentTick.tick / this.getTickRatio() * 10 ) / 10;
      if (next !== this.position$.value) {
        this.position$.next(next);
      }
    });

    this.midiParser.on('endOfFile', () => {
      this.nextSong();
    });

    instrumentsToLoad.forEach(instr => this.midiPlayer.loader.decodeAfterLoading(this.audioContext, instr));

    // init mp3 player events
    this.mp3Player.ontimeupdate = () => {
      this.position$.next(this.mp3Player.currentTime);
    };

    this.mp3Player.onended = () => {
      this.nextSong();
    };

    this.store.pipe(select(selectUrlPrefix)).subscribe(urlPrefix => this.urlPrefix = urlPrefix);

    this.store.pipe(select(selectCurrentSong)).subscribe(song => this.loadSong(song));
    this.store.pipe(select(selectUserProfile)).subscribe(userProfile => this.userProfile = userProfile);

    this.store.pipe(
      select(selectCurrentSongList),
      skipWhile( v => !v )
    ).subscribe(sl => this.currentSongList = sl);

    // tslint:disable: no-string-literal
    commonNames['Sopran'] = 'S';
    commonNames['Sopran1'] = 'S1';
    commonNames['Sopran 1'] = 'S1';
    commonNames['Sopran2'] = 'S2';
    commonNames['Sopran 2'] = 'S2';

    commonNames['Alt'] = 'A';
    commonNames['Alt1'] = 'A1';
    commonNames['Alt 1'] = 'A1';
    commonNames['Alt2'] = 'A2';
    commonNames['Alt 2'] = 'A2';

    commonNames['Tenor'] = 'T';
    commonNames['Tenor1'] = 'T1';
    commonNames['Tenor 1'] = 'T1';
    commonNames['Tenor2'] = 'T2';
    commonNames['Tenor 2'] = 'T2';

    commonNames['Bas'] = 'B';
    commonNames['Bas1'] = 'B1';
    commonNames['Bas 1'] = 'B1';
    commonNames['Bas2'] = 'B2';
    commonNames['Bas 2'] = 'B2';
    // tslint:enable: no-string-literal
  }

  private roundTo1(value: number): number {
    return Math.round(value * 10) / 10;
  }

  public nextSong() {
    this.stopAllVoices();
    this.playOnNextLoad = this.currentSongList && this.isPlaying();
    this.midiParser.stop();
    this.mp3Player.pause();

    this.isPlaying$.next(false);
    this.position$.next(0);

    if (this.currentSongList) {
      const oldPos = this.currentSongList.songIds.indexOf(this.currentSong.id);
      let newPos = oldPos + 1;
      if (newPos >= this.currentSongList.songIds.length) {
        newPos = 0;
      }
      this.store.dispatch(setCurrentSong({id: this.currentSongList.songIds[newPos]}));
    }
  }

  private stopAllVoices(): void {
    this.trackNames.forEach(t => this.stopPlaying(this.trackNumByName[t]));
  }

  private stopPlaying(track: number) {
    if (this.notes[track] !== undefined) {
      this.notes[track].audioBufferSourceNode.stop(0);
      this.notes[track] = undefined;
    }
  }

  public async loadSong(song: Song) {
    if (this.currentSong && song.id === this.currentSong.id) {
      return;
    }
    this.currentSong = song;
    const url = matchSecurity(this.urlPrefix + song.midifile);

    const res = await this.http.get(url, {responseType: 'blob'}).toPromise();
    this.midiParser.stop();

    const base64data: string = await this.readbase64(res);
    this.midiParser.loadDataUri(base64data);
    // something wrong in the type-definitions, the return value is an array of an array of events.
    const events = this.midiParser.getEvents() as unknown as midiplayer.Event[][];
    this.parseChannels(events);
    this.tickRatio = -1;
    this.duration$.next(this.roundTo1(this.midiParser.getSongTime()));
    this.position$.next(0);
    this.isPlaying$.next(false);
    this.tracknames$.next(this.trackNames);
    this.tempo$.next(100);
    this.originalMidiTempo = this.midiParser.tempo;
    this.currentLyrics$.next([]);
    console.log('parser: ', this.midiParser);
    if (song.duration !== this.duration$.value) {
      this.store.dispatch(updateSongs({songs: [{
        ...song,
        duration: this.duration$.value
      }]}));
    }

    const prio = this.userProfile.voice.prio;
    for (const v in prio) {
      if (this.trackNumByName[prio[v]] !== undefined) {
        this.setVoice(prio[v]);
        break;
      }
    }

    if (this.playOnNextLoad) {
      this.play();
      this.playOnNextLoad = false;
    }
  }

  private async readbase64(base64Data: Blob): Promise<string> {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result as string);
      };
      temporaryFileReader.readAsDataURL(base64Data);
    });
  }

  private parseChannels(channels: midiplayer.Event[][]) {
    // FIRST LEVEL OF
    this.trackNamesByNum = {};
    this.trackNumByName = {};
    this.trackNames = [];
    this.instruments = {};
    this.volume = {};
    this.lyrics = {};
    channels.forEach(c => this.parseChannel(c));
  }

  private parseChannel(channel: midiplayer.Event[]) {
    const lyrics: LyricsSyllable[][] = [[]];
    let track = -1;
    let trackName: string;
    let hasMusic = false;
    let syllableCount = 0;
    channel.forEach(event => {
      if (event.name === 'Sequence/Track Name') {

        trackName = event.string;
        if (commonNames[trackName] !== undefined) {
          trackName = commonNames[trackName];
        }

        track = event.track;
      }

      if (event.name === 'Note on') {
        hasMusic = true;
      }
      if (event.name === 'Lyric') {
        if (event.string === '\r') {
          lyrics.push([]);
        } else {

          lyrics[lyrics.length - 1].push({syllable: event.string, time: event.tick / this.getTickRatio(), index: syllableCount});
          syllableCount++;
        }
      }
    });
    if (track !== -1 && hasMusic) {
      if (lyrics[lyrics.length - 1].length !== 0) {
        this.lyrics[track] = lyrics;
      }
      this.instruments[track] = this.piano;
      this.volume[track] = 100;
      this.trackNamesByNum[track] = trackName;
      this.trackNumByName[trackName] = track;
      this.trackNames.push(trackName);
    }
  }

  private playMidiEvent(ev: midiplayer.Event) {
    if (ev.name === 'Lyric') {
      // console.log(`${this.trackNamesByNum[ev.track]}: ${ev.string}`);
    } else if (ev.name === 'Note on') {
      if (ev.velocity !== 0) {
        this.stopPlaying(ev.track);
        const volume = this.volume[ev.track] / 100;
        this.playNote(ev.track, ev.noteNumber, (ev.velocity / 255) * volume);
      } else {
        this.stopPlaying(ev.track);
      }
    } else {
      //  (ev);
    }
  }

  private playNote(track: number, noteNumber: number, volume: number) {
    const instrument = this.instruments[track];
    this.notes[track] = this.midiPlayer
      .queueWaveTable(this.audioContext, // audioContext
        this.audioContext.destination, // target
        instrument, // preset
        0, // when
        noteNumber, // pitch
        100, // duration
        volume // volume
        // slides
        );
  }

  private setInstrument(track: string, instrument: any) {
    this.instruments[this.trackNumByName[track]] = instrument;
  }

  public setPiano(track: string) {
    this.setInstrument(track, this.piano);
  }

  public setOboe(track: string) {
    this.setInstrument(track, this.oboe);
  }

  public setVolume(track: string, volume: number) {
    if (volume === 0) {
      volume = 0.0001;
    }
    this.volume[this.trackNumByName[track]] = volume;
  }

  public setVoice(track: string) {
    this.currentLyrics$.next(this.lyrics[this.trackNumByName[track]]);

    if (this.mode === PlayerMode.mp3) {
      const wasPlaying = this.isPlaying();
      const tempPos = this.mp3Player.currentTime;
      this.mp3Player.src = matchSecurity(this.urlPrefix + this.currentSong.mp3[track]);
      this.mp3Player.currentTime = tempPos;
      if (wasPlaying) {
        this.mp3Player.play();
      }
    } else if (this.mode === PlayerMode.midi) {
      this.trackNames.forEach(t => {
        if (t === track) {
          this.setOboe(t);
          this.setVolume(t, 100);
        } else {
          this.setPiano(t);
          this.setVolume(t, 50);
        }
      });
    }
  }

  public pause() {
    this.midiParser.pause();
    this.mp3Player.pause();

    this.trackNames.forEach(name => {
      const t = this.trackNumByName[name];
      if (this.notes[t] !== undefined) {
        this.notes[t].audioBufferSourceNode.stop(0);
        this.notes[t] = undefined;
      }
    });

    this.position$.next(this.currentPosition());
    this.isPlaying$.next(false);
  }

  setTempo(value: number) {
    if (this.mode === PlayerMode.mp3) {
      this.mp3Player.playbackRate = value / 100;
    } else if (this.mode === PlayerMode.midi) {
      this.midiParser.tempo = value / 100 * this.originalMidiTempo;
    }
  }

  public seek(seconds: number) {
    this.rewind(this.currentPosition() - seconds);
  }

  private currentPosition(): number {
    if (this.mode === PlayerMode.mp3) {
      return this.mp3Player.currentTime;
    }
    return this.midiParser.getSongTime() - this.midiParser.getSongTimeRemaining();
  }

  public rewindToStart() {
    this.rewind(this.currentPosition());
  }

  public rewind(seconds: number) {
    const wasPlaying = this.isPlaying();
    let newPosition = this.currentPosition() - seconds;
    this.midiParser.pause();

    if (newPosition < 0) {
      newPosition = 0;
    }

    if (this.mode === PlayerMode.mp3) {
      this.mp3Player.pause();
      this.mp3Player.currentTime = newPosition;
      if (wasPlaying) {
        this.mp3Player.play();
      }
    } else if (this.mode === PlayerMode.midi) {
      this.midiParser.stop();
      this.midiParser.skipToTick(newPosition * this.getTickRatio());
      if (wasPlaying) {
        this.midiParser.play();
      }
    }
    this.position$.next(newPosition);
  }

  isPlaying() {
    if (this.mode === PlayerMode.mp3) {
      return !this.mp3Player.paused;
    }
    return this.midiParser.isPlaying();
  }

  public play() {
    if (this.mode === PlayerMode.mp3) {
      this.mp3Player.play();
    } else if (this.mode === PlayerMode.midi) {
      if (this.midiParser.isPlaying()) {
        this.midiParser.stop();
      }
      this.midiParser.play();
    }
    this.isPlaying$.next(true);
  }
}
